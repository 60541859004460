import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', label: 'Course Name', sortable: true },
    { key: 'coursemode', sortable: true },
    { key: 'participants', label: 'participants', sortable: false },
    { key: 'id', label: 'lectures', sortable: false },
    { key: 'status', sortable: true },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const items = ref()

  const dataMeta = computed(() => {
    const localItemsCount = items.value ? items.value.length : 0
    var from = perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0)
    return {
      from: from,
      to: (from + perPage.value) > totalUsers.value ? totalUsers.value + 1 : from + perPage.value,
      of: totalUsers.value,
    }
  })


  const fetchCourses = (ctx, callback) => {
    store
      .dispatch('app-course/fetchCourses', {

        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data } = response.data
        //console.log("resolved Data");
        //console.log(response.data);
        items.value = data;
        totalUsers.value = data.length;
        // callback(data)

      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const refetchData = () => {
    fetchCourses()
  }


  fetchCourses()
  const draftCourse = (ctx, callback) => {
    store
      .dispatch('app-course/draftCourse', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {


        const { data } = response.data

        callback(data)


      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating course status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    //console.log("status " + status);
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status === 400) return 'deleted'
    if (status === 1) return 'published'
    if (status === 0) return 'draft'
    return 'primary'
  }

  const resolvePageroute = coursemode => {
    if (coursemode === 'IN-PERSON') return 'course-sessions'
    if (coursemode === 'ONLINE') return 'course-lessons'
    if (coursemode === 'HYBRID') return 'course-lessons'

    return 'course-lessons'
  }
  const resolveCourseContent = coursemode => {
    if (coursemode === 'IN-PERSON') return 'Sessions'
    if (coursemode === 'ONLINE') return 'Lectures'
    if (coursemode === 'HYBRID') return 'Lectures'

    return 'Lectures'
  }


  return {
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    items,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resolvePageroute,
    resolveCourseContent
  }
}
