<template>

  <div>
    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap">{{ $t("Create Course") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table id="my-table" ref="refUserListTable" class="position-relative" :filter="searchQuery" :items="items" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty @filtered="onFiltered"
        :empty-text="$t('No matching records found')" :sort-desc.sync="isSortDirDesc" :per-page="perPage"
        :current-page="currentPage">

        <template #head()="data">
          <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
        </template>
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }" />
            </template>
            <b-link :to="{ name: 'course-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.courseCode }}</small>
          </b-media>
        </template>
        <template #cell(price)="data">
          <span v-if="Boolean(data.item.isPaid)">
            {{ data.item.price }}
            <small class="text-muted">{{ data.item.pricecurrency }}</small>
          </span>
          <span v-if="!Boolean(data.item.isPaid)">
            FREE
          </span>
        </template>
        <template #cell(participants)="data">
          <b-link
            :to="{ name: 'partipiciants-bycourse-page', params: { id: data.item.courseguid, coursename: data.item.title,courseId:data.item.id } }"
            class="font-weight-bold d-block text-nowrap">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`" /> {{ $t("Participants") }}
          </b-link>
        </template>
        <template #cell(id)="data">
          <b-link :to="getPageroute(data.item.coursemode, data.item.courseguid)"
            class="font-weight-bold d-block text-nowrap">
            {{ $t(resolveCourseContent(data.item.coursemode)) }}
          </b-link>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-dropdown :variant="`${getStatus(data.item.courseStatus)}`" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              {{ $t(getStatusText(data.item.courseStatus)) }}
            </template>
            <b-dropdown-item>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-success"
                @click="publishCourse(data.item)">

                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">{{ $t("Publish") }}</span>
              </b-button>
            </b-dropdown-item>

            <b-dropdown-item>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-secondary"
                @click="draftCourse(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Switch to Draft") }}</span>
              </b-button>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                @click="deleteCourse(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t("Remove Course") }}</span>
              </b-button>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{
              $t('of') }}
              {{ dataMeta.of }}
              {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              aria-controls="my-table" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './CoursesListFilters.vue'
import useUsersList from './useCoursesList'
import userStoreModule from '../courseStoreModule'
import UserListAddNew from './CourseListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {

  computed: {
 
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalUsers = filteredItems.length
      this.currentPage = 1
    },
    matchesFilter(item) {
      const searchTerm = this.searchQuery.toString().toLowerCase();

      // For status filter
      const statusMatch = this.getStatusText(item.courseStatus).toLowerCase().includes(searchTerm);
      const priceMatch = ('free'.includes(searchTerm) && (!item.isPaid));

      const courseModeMatch = item.coursemode.toLowerCase().includes(searchTerm);
      var tempPriceMatch = false;
      if (item.isPaid) {
        tempPriceMatch = item.price.toLowerCase().includes(searchTerm);
      } else {
        //console.log("FALSEE")
        tempPriceMatch = false;
      }
      const courseCodeMatch = item.courseCode.toLowerCase().includes(searchTerm);
      const titleMatch = item.title.toLowerCase().includes(searchTerm);


      return statusMatch || courseModeMatch || titleMatch || courseCodeMatch || tempPriceMatch || priceMatch;
    },
    getRoute(coursemode) {

      return "page-coursemode";
    }
    ,

    publishCourse(coursedata) {
      //console.log(coursedata);
      store
        .dispatch('app-course/publishCourse', {
          id: coursedata.courseguid,

        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 100;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
    },

    draftCourse(coursedata) {

      store
        .dispatch('app-course/draftCourse', {
          id: coursedata.courseguid,
        })
        .then(response => {

          var result = response.data.success;
          if (result != null && result != undefined && result == 100) {
            coursedata.courseStatus = 0;

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;

          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            this.isLoading = false;
            this.isUpdated = false;
          }


        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating course status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },


  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    ToastificationContent
  }, directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-course'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const getPageroute = (coursemode, courseguid) => {
      //console.log(coursemode);
      var routename = "";
      if (coursemode === 'IN-PERSON') routename = 'course-sessions'
      else if (coursemode === 'ONLINE') routename = 'course-lectures'
      else if (coursemode === 'HYBRID') routename = 'course-lectures'
      else routename = 'course-lectures';
      var result = { name: routename, params: { id: courseguid } };

      return result;


    }
    const getStatus = status => {



      // DRAFT 
      if (status == '0') return 'secondary'
      // published
      if (status == '100') return 'success'

      // ended by time limit
      if (status == '200') return 'warning'
      // stopped 
      if (status == '500') return 'warning'

      return 'secondary';


    }
    const getStatusText = status => {
      // DRAFT 
      if (status == '0') return 'Draft'
      // published
      if (status == '100') return 'Published'
      // ended by time limit
      if (status == '200') return 'Ended'
      // stopped 
      if (status == '500') return 'Stopped'

      return 'Draft';


    }


    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      items,
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
    } = useUsersList()

    const deleteCourse = (coursedata) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result) {
          store.dispatch('app-course/deleteCourse', {
            id: coursedata.courseguid,
          }).then(e => {
            fetchCourses()
          })
        }
      })
    }

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      items,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,
      deleteCourse,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      getPageroute,
      getStatus,
      getStatusText
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
